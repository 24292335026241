import { useAllRecord, useSchema, AuthService, EntityManager } from "@gemini-projects/gemini-react-admin-app"
import { Button, Card, Collapse, Spin } from "antd"
import { DiffEntityBody } from "customActions/entityActions";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { diffEntity } from "../customActions/entityActions";
import { DoubleRightOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import Axios from "axios";

const EntityMoveBody = (props: {
    namespace: string, entity: string, targetNamespace: string, onDiff: (diff: Boolean) => void,
    style?: React.CSSProperties
}
) => {
    const { entity, namespace, targetNamespace, onDiff, style } = props;

    const [sourceRecords] = useAllRecord({ entity, namespace })
    const [targetRecords] = useAllRecord({ entity, namespace: targetNamespace })
    const [schemaData] = useSchema(props)
    const schema = schemaData.data?.entity;

    const diffArray = schema ? diffEntity(sourceRecords, targetRecords, schema) : undefined;


    useEffect(() => {
        if (diffArray)
            onDiff(diffArray.length > 0)
    }, [sourceRecords, targetRecords])   // eslint-disable-line react-hooks/exhaustive-deps


    if (schema && diffArray) {
        return diffArray.length > 0 ? <Collapse style={style}>
            <Collapse.Panel header={schema.displayName ?? schema.name} key="cp" >
                <DiffEntityBody {...props} diffArray={diffArray} />
            </Collapse.Panel>
        </Collapse> : null

    }
    return null;
}

const NamespaceMoveCard = (props: { namespace: string, targetNamespaces: string[] }) => {
    const { namespace, targetNamespaces } = props;

    const [smDiff, setSmDiff] = useState<Boolean[]>([])
    const [mlDiff, setMlDiff] = useState<Boolean[]>([])

    const [smUpating, setSmUpdating] = useState(false)
    const [mlUpating, setMlUpdating] = useState(false)
    const [allUpdating, setAllUpdating] = useState(false)


    const [namespaces] = useAllRecord({ entity: "NAMESPACE" });

    const smHasDiff = smDiff.reduce((p, c) => p || c, false)
    const mlHasDiff = mlDiff.reduce((p, c) => p || c, false)

    const moveEnvFn = async (sourceNs: any, targetNs: any, scope: string, loadingFn: Dispatch<SetStateAction<boolean>>) => {
        loadingFn(true)
        const accessToken = AuthService.getAccessToken()
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken } }
        const uri = targetNs.url + (targetNs.url[targetNs.url.length - 1] === '/' ? 'updateenv' : '/updateenv')
        try {
            await Axios.post(uri, { scope, environment: sourceNs.id }, httpConfig)
            await EntityManager.checkUpdates({ namespace: sourceNs.id })
            await EntityManager.checkUpdates({ namespace: targetNs.id })
        } catch (e) {
        }
        loadingFn(false)
    }

    if (namespaces.data) {
        const nsRecord = namespaces.data.find(r => r.id === namespace)


        return nsRecord ? <>{targetNamespaces.map(ts => {

            const tnsRecord = namespaces.data!.find(r => r.id === ts);
            return <Spin spinning={allUpdating} key={ts}>
                <Card
                    extra={[(mlHasDiff || smHasDiff) ? <Button key="update" onClick={() => moveEnvFn(nsRecord, tnsRecord, 'ALL', setAllUpdating)}>Update ALL</Button> : <CheckCircleTwoTone key="ok" twoToneColor="#52c41a" />]}
                    title={<span>{nsRecord.displayName} <DoubleRightOutlined /> {tnsRecord.displayName}</span>}>
                    <Spin spinning={smUpating} key="1">
                        <Card key={"sm"} type="inner" title="Session Manager" bodyStyle={smHasDiff ? {} : { padding: 0 }}
                            extra={[smHasDiff ? <Button loading={smUpating} onClick={() => moveEnvFn(nsRecord, tnsRecord, 'SESSION_MANAGER', setSmUpdating)} key="update">Update ALL</Button> : <CheckCircleTwoTone key="ok" twoToneColor="#52c41a" />]}>
                            {["APIF_BASIC_SETTINGS", "ACTION", "JSFILEACTION"].map((entity, index) =>
                                <EntityMoveBody style={{ marginTop: "10px" }} key={entity} entity={entity} namespace={namespace} targetNamespace={ts} onDiff={(diff) => setSmDiff(prev => {
                                    let n = [...prev]
                                    n[index] = diff
                                    return n;
                                })} />
                            )}
                        </Card>
                    </Spin>
                    <Spin spinning={mlUpating} key="2">
                        <Card key={"me"}
                            style={{ marginTop: 16 }}
                            bodyStyle={mlHasDiff ? {} : { padding: 0 }}
                            extra={[mlHasDiff ? <Button key="update" onClick={() => moveEnvFn(nsRecord, tnsRecord, 'ML_ENGINE', setMlUpdating)}>Update ALL</Button> : <CheckCircleTwoTone key="ok" twoToneColor="#52c41a" />]}
                            type="inner"
                            title="ML Engine"
                        >
                            {["ENTITY", "INTENT", "ML_MODEL_CONF", "ML_SERVICE_CONF", "PATTERN_MATCH", "MISSPELLING_DICT"].map((entity, index) =>
                                <EntityMoveBody style={{ marginTop: "8px" }} key={entity} entity={entity} namespace={namespace} targetNamespace={ts} onDiff={(diff) => setMlDiff(prev => {
                                    let n = [...prev]
                                    n[index] = diff
                                    return n;
                                })} />
                            )}
                        </Card>
                    </Spin>
                </Card>
            </Spin>
        })}</> : null

    }
    return null;
}


export const MoveEnv = () => {
    return <div style={{ width: "100%", height: "100%", backgroundColor: "rgb(240, 242, 245)" }}>
        <NamespaceMoveCard key="1" namespace={"APPENGINE_BO"} targetNamespaces={["INTEGRATION_BO"]}></NamespaceMoveCard>

        <div style={{ marginTop: "10px" }}>
            <NamespaceMoveCard key="2" namespace={"INTEGRATION_BO"} targetNamespaces={["STAGING_BO"]}></NamespaceMoveCard>
        </div>

        <div style={{ marginTop: "10px" }}>
            <NamespaceMoveCard key="3" namespace={"STAGING_BO"} targetNamespaces={["PRODUCTION_BO"]}></NamespaceMoveCard>
        </div>
    </div>
}

export default MoveEnv