import { GeminiApp } from '@gemini-projects/gemini-react-admin-app';
import '@gemini-projects/gemini-react-admin-app/dist/index.css'
import axios from 'axios';
import recordActions from './customActions/recordsActions';
import entityActions from './customActions/entityActions'
import customComponent from './components/customComponents'

axios.defaults.baseURL = process.env.REACT_APP_DEFAULT_NAMESPACE_URI


function App() {

  const props = {
    authentication: {
      google: {
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        enabled: true
      }
    }
  }

  return <GeminiApp {...props} recordsActions={recordActions}
    entityActions={entityActions} customComponents={customComponent}
  />
}

export default App;
